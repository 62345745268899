import './App.css';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        ochre: {
            main: '#FD6C01',
            light: '#FD6C01',
            dark: '#FD6C01',
            contrastText: '#FD6C01',
        },
    },
     typography: {
        allVariants: {
          fontFamily: 'NunitoGoogle',
          textTransform: 'none',
          fontWeight: 100,
          fontSize: 24,
        },
        fontWeight: 100
      },
});

function ContactUs() {
    return (
        <ThemeProvider theme={theme}>
            <div className="ContactUs">
                <p>You can send feedbacks or suggestions by clicking this button</p>
                <br></br>
                <Button variant="outlined" color="ochre" href='mailto:batuoztrk99@gmail.com'>Send Email</Button>
                <br></br>
                <br></br>
                <p>You can create account deletion request by clicking button, we will reply how to delete your data on Konsol.</p>
                <br></br>
                <Button variant="outlined" color="ochre" href='mailto:batuoztrk99@gmail.com?subject=User%20Data%20Delete%20Request'>Create Request</Button>

            </div>
        </ThemeProvider>

    );
}

export default ContactUs;
