import './App.css';
import * as React from 'react';
import management1 from './konsol-screenshots/management1.png';
import management2 from './konsol-screenshots/management2.png';
import firestore1 from './konsol-screenshots/firestore1.png';
import firestore2 from './konsol-screenshots/firestore2.png';
import fcm1 from './konsol-screenshots/fcm1.png';
import fcm2 from './konsol-screenshots/fcm2.png';
import cloudtorage1 from './konsol-screenshots/cloudstorage1.png';
import cloudstorage2 from './konsol-screenshots/cloudstorage2.png';
import testlab1 from './konsol-screenshots/testlab1.png';
import testlab2 from './konsol-screenshots/testlab2.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'

function Home() {
  return (
    <div>
      <Grid container spacing={8} direction={"row"} >
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={management1} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} direction={"column"} style={{ display: 'flex', alignItems: 'start' }}>
          <p style={{ fontSize: 40, fontWeight: 500 }}>Manage your Firebase projects</p>
          <p>Create Firebase project* from scratch or using existing Google Cloud Project</p>
          <p>Update project (project name, billing info**, Analytics info***, delete project)</p>
          <p>Add Android / iOS / web apps & download configuration files</p>
          <p style={{ fontSize: 20 }}>* requires accepting Google Cloud ToS & Firebase ToS.</p>
          <p style={{ fontSize: 20 }}>** requires at least one billing account.</p>
          <p style={{ fontSize: 20 }}>*** requires at least one Analytics account.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={management2} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={8} direction={"row"} >
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={firestore1} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} direction={"column"} style={{ display: 'flex', alignItems: 'start' }}>
          <p style={{ fontSize: 40, fontWeight: 500 }}>Update your Firestore data</p>
          <p>Create database by enabling Firestore</p>
          <p>Create / view collection</p>
          <p>Create / delete document</p>
          <p>View / create / update / delete document fields</p>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={firestore2} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={8} direction={"row"} >
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={testlab1} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} direction={"column"} style={{ display: 'flex', alignItems: 'start' }}>
          <p style={{ fontSize: 40, fontWeight: 500 }}>Test your app without having any device</p>
          <p>Create test matrix by uploading/selected apk or aab files*</p>
          <p>View matrix results</p>
          <p>View matrix execution details</p>
          <p style={{ fontSize: 20 }}>* Android only. If your Firebase project was created after 28/09/2023, project must be linked to a billing account (Blaze Plan) due to prohibitation of uploading executable files (.apk, .exe, .ipa files.). If your Firebase project was created before that date, you can still test your Android app without adding billing account.</p>

        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={testlab2} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={8} direction={"row"} >
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={fcm1} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} direction={"column"} style={{ display: 'flex', alignItems: 'start' }}>
          <p style={{ fontSize: 40, fontWeight: 500 }}>Send FCM notifications with less effort</p>
          <p>Send notifications to one device*</p>
          <p>Send notifications to mutliple devices which are subscribed to the topic**</p>
          <br></br>
          <p style={{ fontSize: 20 }}>* with Token ID.</p>
          <p style={{ fontSize: 20 }}>** with topic subscription.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={fcm2} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={8} direction={"row"} >
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={cloudtorage1} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} direction={"column"} style={{ display: 'flex', alignItems: 'start' }}>
          <p style={{ fontSize: 40, fontWeight: 500 }}>Upload any type of file to Cloud Storage</p>
            <p>Upload any type of file*</p>
            <p>Delete file </p>
            <p>Create / delete folder </p>
            <p style={{ fontSize: 20 }}>* If your Firebase project was created after 28 September 2023 and this project's plan is Spark plan, this feature is partially used due to new Firebase rule regarding prohibiting to host/upload executable files on Cloud Storage affecting Spark plan, which are .exe, .ipa & .apk files.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={cloudstorage2} alt="Screenshot" style={{ height: "90vh" }} />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Grid container spacing={8} direction={"row"} style={{ width: "100vw" }} >
        <Grid item xs={0} sm={0} md={3} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p style={{ fontSize: 20 }}>Firebase, Test Lab, Cloud Storage, Firestore and FCM are trademarks of Google LLC.</p>
          <p style={{ fontSize: 20 }}>Android is a trademark of Google LLC.</p>
          <p style={{ fontSize: 20 }}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
          <p style={{ fontSize: 20 }}>Konsol, &copy;2023 Batuhan Öztürk. All rights reserved.</p>
        </Grid>
        <Grid item xs={0} sm={0} md={3} lg={3}></Grid>
      </Grid>
    </div>

  );
}

export default Home;
